import Header from '../../Header.vue'
import Footer from '../../Footer.vue'
import NoData from '../../../components/NoData.vue'
import page from '../../../components/page.vue'
import CheckInService from '../../../api/CheckInService.js'
 
export default{
	name:'AcademicDynamicsList',
	data(){
		return{
			pageIndex:1,
			pageSize:10,
			PageCount:0,
			DynamicList:[],
			hotNewsList:[],
			HotDynamicList:[]
		}
	},
	methods:{
		GetAcademicDynamicList(){ //获取学界动态
			var that = this
			CheckInService.GetAcademicDynamicList(that.pageIndex,that.pageSize).then(res=>{
				if(res.Tag == 1){
					that.DynamicList = res.Result
					that.PageCount = res.TotalCount
				}
			})
		},
		pageChange(page){
			this.pageIndex = page
			this.GetAcademicDynamicList()
			window.scrollTo(0,0)
		},
		GetHotDynamicList(){
			var that = this
			CheckInService.GetHotAcademicDynamicList(5).then(res=>{
				if(res.Tag == 1){
					that.HotDynamicList = res.Result
				}
			})
		},
		GetNewsHotList(){ //获取热门新闻
			var that = this
			CheckInService.GetNewsHotList(5).then(res=>{
				if(res.Tag == 1){
					that.hotNewsList = res.Result
				}
			})
		}
	},
	created(){
		this.GetAcademicDynamicList()
		this.GetNewsHotList()
		this.GetHotDynamicList()
	},
	components:{
		'Header': Header,
		'Footer':Footer,
		'NoData':NoData,
		'page':page
	}
}